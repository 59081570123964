.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.navigation div.navi {
  float: left;
}
.navigation div.navi > .item {
  float: left;
}
.navigation div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 > .item {
  margin: 0 25px;
}
.navigation div.sub1 > .item.init {
  margin-left: 0;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0;
}
.navigation div.sub1 > .item > .menu {
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-style: italic;
  color: #A5896E;
  font-size: 18px;
  line-height: 2.22222222;
  padding: 8px 0 13px;
  border-bottom: 4px solid transparent;
}
.navigation div.sub1 > .item > .menu:hover,
.navigation div.sub1 > .item > .menu:focus {
  border-color: #A5896E;
}
.navigation div.sub1 > .item > .menu.path {
  border-color: #A5896E;
}
.cb-layout2 #head {
  margin-top: 175px;
}
@media (max-width: 1023px) {
  .cb-layout2 #head {
    margin-top: 175px;
  }
}
@media (max-width: 767px) {
  .cb-layout2 #head {
    margin-top: 175px;
  }
}
.footarea {
  width: 138.9261745%;
  margin-left: -11.0738255%;
  display: flex;
  flex-wrap: wrap;
}
.footpart {
  margin-left: 7.97101449%;
  margin-right: 7.97101449%;
}
.footpart.footpart--slim {
  width: 28.01932367%;
}
.footpart.footpart--wide {
  width: 71.98067633%;
}
.section--footer .vcard address {
  flex-direction: row;
}
.vcard .row.row--left {
  margin-right: 25px;
}
.vcard .row.row--right {
  margin-left: 25px;
  margin-top: 0;
}
h1 {
  font-size: 28px;
  line-height: 1.42857143;
}
.section--zero h2 {
  font-size: 135px;
  line-height: 1;
}
h3 {
  font-size: 60px;
  line-height: 1;
}
h5 {
  font-size: 30px;
  line-height: 1;
}
p.loud {
  font-size: 24px;
  line-height: 1.41666667;
}
p.skew {
  font-size: 28px;
  line-height: 1.42857143;
}
.section--one .area {
  width: 100%;
}
.section--one .area > .unit {
  margin-right: 2.01288245%;
  margin-left: 2.01288245%;
  width: 95.9742351%;
}
.section--one .area .cb-album .body,
.section--one .area .head,
.section--one .area .foot,
.section--one .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim {
  width: 45.9742351%;
}
.section--one .area > .slim .head,
.section--one .area > .slim .foot,
.section--one .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--one .area > .slim .tiny {
  width: 100%;
}
.section--four .area {
  width: 100%;
}
.section--four .area > .unit {
  margin-right: 2.01288245%;
  margin-left: 2.01288245%;
  width: 95.9742351%;
}
.section--four .area .cb-album .body,
.section--four .area .head,
.section--four .area .foot,
.section--four .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim {
  width: 29.30756844%;
}
.section--four .area > .slim .head,
.section--four .area > .slim .foot,
.section--four .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--four .area > .slim .tiny {
  width: 100%;
}
.section--six .area {
  width: 100%;
}
.section--six .area > .unit {
  margin-right: 2.01288245%;
  margin-left: 2.01288245%;
  width: 95.9742351%;
}
.section--six .area .cb-album .body,
.section--six .area .head,
.section--six .area .foot,
.section--six .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim {
  width: 29.30756844%;
}
.section--six .area > .slim .head,
.section--six .area > .slim .foot,
.section--six .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--six .area > .slim .tiny {
  width: 100%;
}
.section--one .area {
  width: 104.19463087%;
  margin-left: -2.09731544%;
  display: flex;
  flex-wrap: wrap;
}
.section--four .area {
  width: 104.19463087%;
  margin-left: -2.09731544%;
  display: flex;
  flex-wrap: wrap;
}
.section--six .area {
  width: 104.19463087%;
  margin-left: -2.09731544%;
  display: flex;
  flex-wrap: wrap;
}
.section--one .area .wide.seam {
  width: 62.64090177%;
  margin-left: 18.67954911%;
  margin-right: 18.67954911%;
}
.section--one .area .slim.seam {
  padding-top: 80px;
  box-sizing: border-box;
}
.section--two .area .pure .body {
  padding-left: 17.36577181%;
  box-sizing: border-box;
}
.section--two .area .pure .part.pict.tall:first-child {
  position: absolute;
  left: 0;
  width: 130px;
}
.section--two .area .pure .part.pict.tall:first-child .cb-image-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.section--two .area .pure .part.tall {
  width: 57.8680203%;
  float: right;
}
.section--two .area .pure .part.tiny {
  width: 36.85279188%;
  float: left;
}
.section--four .area .unit .part {
  margin-top: 25px;
  margin-bottom: 25px;
}
.section--four .area .seam.wide {
  width: 62.64090177%;
}
/*# sourceMappingURL=./screen-large.css.map */